import React from "react";
import { Layout } from "../../components/common/layout";
import { Banner } from "../../components/common/banner";
import { Intent } from "../../components/common/intent";
import backgroundIMG from "../../components/assets/img/pages/solucoes/subpages/ic-controle-de-acesso.jpg";

const AcessoPage = () => (
        <Layout infoPage={{page: "Controle de Acesso"}}>
            <main className="solucoes-subpage">
                <Banner backgroundImage={backgroundIMG}>
                    <h1 className="title bold">CONTROLE<br/><span className="bold" style={{ color: "#FFF" }}>DE ACESSO</span></h1>
                    <div className="gray-box">
                        <span className="text">Solução em controle de colaboradores nas Empresas e moradores em Condomínios utilizando Tecnologia RFID Ativa e Passiva.</span>
                    </div>
                </Banner>
                <section className="content">
                    <p className="text"><span className="bold orange">Utilizamos o que há de mais moderno para controle</span> e rastreabilidade de pessoas, tanto corporativa quanto em condomínios.</p>
                    <p className="text">Com a nossa solução você terá em suas mãos todas as movimentações ocorridas pelo seus colaboradores nas entradas e saídas que você queira monitorar</p>
                    <p className="text">Saiba a data, hora, minuto e segundo que o seu colaborador entrou e saiu da empresa.<br/>O nosso sistema registra todas as informações referentes às movimentações de entrada e saída.<br/>Saiba quantas horas o seu funcionário trabalhou de fato, saiba a jornada de trabalho de cada colaborador, que horas ele entrou e saiu da empresa.</p>
                    <p className="text">Reduza seus custos com assessoria jurídica e indenização com processos trabalhistas.</p>
                    <p className="text">Oferemos soluções para controle de acesso com Tecnologia RTLS (Real Time Location System) nas frequências 433Mhz e 2.45GHz utilizando crachás eletrônicos (Active Tags, Tags Ativas, Badge Tag)</p>
                    <Intent classes="button" to="/#solutions">
                        <span className="medium">DEMAIS SOLUÇÕES</span>
                    </Intent>
                </section>
            </main>
        </Layout>
    )

export default AcessoPage;